import React from 'react';
import { LocalizationContext } from '../../../hoc/Localization';
import styles from '../styles.less';

const SuccessView = () => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <>
      <div className={`${styles.container}`}>
        <section>
          <h1>{t('custom.email.success')}</h1>
          <div className={styles.inputContainer}>
            <img src="/static/images/login/email.png" alt="search" />
            <p className={styles.successMessage}>{t('custom.email.success.message')}</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default SuccessView;
