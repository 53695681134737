import { RegisterFlowStepsEnum } from '../enums/StepsEnum';
import { RegisterFlowTypesEnum } from '../enums/TypesEnum';
import { RegisterFlowTypeStepInterface } from '../interfaces/TypeStepInterface';

export const RegisterFlowTypeSteps: Array<RegisterFlowTypeStepInterface> = [
  {
    type: RegisterFlowTypesEnum.SIMPLE,
    steps: [
      RegisterFlowStepsEnum.LOGIN_BUTTONS,
      RegisterFlowStepsEnum.SUBSCRIBE,
      RegisterFlowStepsEnum.PREFERENCES,
      //RegisterFlowStepsEnum.WELCOME,
    ],
  },
  {
    type: RegisterFlowTypesEnum.REWARD,
    steps: [
      RegisterFlowStepsEnum.REWARD,
      RegisterFlowStepsEnum.LOGIN_BUTTONS,
      RegisterFlowStepsEnum.SUBSCRIBE,
      RegisterFlowStepsEnum.PREFERENCES,
      RegisterFlowStepsEnum.REWARD_CONGRATS,
    ],
  },
];
