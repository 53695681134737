import React from 'react';
import PropTypes from 'prop-types';
import MeinView from './loginViews/mainView';
import EmailView from './loginViews/emailView';
import SuccessView from './loginViews/successView';

const LoginDialog = ({ children, id, onLogin, title }) => {
  const [step, setStep] = React.useState(1);

  const childrenWithProps = React.Children.map(children, child => React.cloneElement(child));
  React.useEffect(() => {
    if (window.gapi) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init();
      });
    }
  });

  const views = {
    1: <MeinView onLogin={onLogin} title={title} setStep={setStep} />,
    2: <EmailView setStep={setStep} />,
    3: <SuccessView />,
  };

  return (
    <>
      {views[step]}
      {id ? (
        <div id={id} className="idPlaceholder">
          {childrenWithProps}
        </div>
      ) : (
        childrenWithProps
      )}
    </>
  );
};

LoginDialog.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  onLogin: PropTypes.func,
  title: PropTypes.string,
};

LoginDialog.defaultProps = {
  id: null,
  children: null,
  title: '',
  onLogin: () => {},
};

export default LoginDialog;
