import React, { useState } from 'react'
import Button from '../Button';

const ContinueButton: React.FunctionComponent<{
    text: string,
    nextStep: () => any,
    beforeNextStep?: () => Promise<boolean>
}> = ({ text, nextStep, beforeNextStep }) => {
    const [buttonLoading, setButtonLoading] = useState(false);

    return (
        <Button
            version="large"
            type="primary"
            className=""
            style={{ width: '100%' }}
            loading={buttonLoading}
            onClick={async (e) => {
                setButtonLoading(true)

                let stepSuccess = true
                if (beforeNextStep) {
                    stepSuccess = await beforeNextStep()
                }

                setButtonLoading(false)
                stepSuccess && nextStep()
            }}
        >
            {text}
        </Button>
    )
};

export default ContinueButton