import React from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { UserContext } from '../../../hoc/User';
import { LocalizationContext } from '../../../hoc/Localization';
import Button from '../../Button';
import Input from '../../Input';
import styles from '../styles.less';

const EmailView = ({ setStep }) => {
  const [email, setEmail] = React.useState('');

  const { emailVerify } = React.useContext(UserContext);
  const { t } = React.useContext(LocalizationContext);

  function handleEmailVerify() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(email)) {
      notification.error({
        message: 'Not correct email!',
      });
    } else {
      emailVerify({ email });
      setEmail('');
      setStep(3);
    }
  }

  return (
    <>
      <div className={`${styles.container}`}>
        <h1>{t('custom.email.title')}</h1>
        <section>
          <p>{t('custom.email.message')}</p>
          <div className={styles.inputContainer}>
            <Input
              version="long"
              size="large"
              name="emailInput"
              placeholder={t('email.type.your.email')}
              className={`${styles.emailInput} metro`}
              value={email}
              onInput={e => setEmail(e.target.value || null)}
            />
          </div>
          <div className={styles.loginButton}>
            <Button
              version="large"
              type="primary"
              name="loginButton"
              className={styles.kepLoginEmail}
              onClick={handleEmailVerify}
            >
              {t('email.login.button.text')}
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

EmailView.propTypes = {
  setStep: PropTypes.func,
};

EmailView.defaultProps = {
  setStep: () => { },
};

export default EmailView;
