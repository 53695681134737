import React, { useEffect, useState, useCallback } from 'react';
import { RegisterFlowTypesEnum } from './enums/TypesEnum';
import { RegisterFlowStepsEnum } from './enums/StepsEnum';
import Modal from '../Modal';
import { ModalPropsInterface } from '../Modal/Interfaces/ModalInterface';
import { LayoutTypesEnum } from '../Modal/Enums/LayoutTypesEnum';
import styles from './styles.less';
import ContinueButton from './ContinueButton';
import LoginDialog from '../loginDialog';
import { RegisterFlowTypeSteps } from './configs/TypeToSteps';
import { LocalizationContext } from '../../hoc/Localization';
import { UserContext } from '../../hoc/User';
import { SettingsContext } from '../../hoc/Settings';
import { RouteContext } from '../../hoc/Route';
import Router from '../../routes';
import dynamic from 'next/dynamic';
import { cookieExist } from '../../utils/cookies';
import { notification } from 'antd';
import { subscribeToList } from '../../utils/api';
import api from '../../utils/api';
import { getCookie } from '../../utils/cookies';
import { FacebookShareButton } from 'react-share';
import { UtmSources, UtmContents } from 'enums';

const Subscribe = dynamic(() => import('./steps/subscribe'));
const Preferences = dynamic(() => import('./steps/preferences'));

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const formDataInitialValues: {
    email: string,
    phone: string,
    newsletter: boolean,
    discounted: boolean,
    preferences: { [key: string]: boolean }
} = {
    email: null,
    phone: null,
    newsletter: true,
    discounted: true,
    preferences: {}
}

const RegisterFlow: React.FunctionComponent<{ initVisible?: boolean }> = ({
    initVisible = false,
}) => {
    const [step, setStep] = useState(RegisterFlowStepsEnum.EMPTY);
    const [visible, setVisible] = useState(initVisible);
    const [userNotClosed, setUserNotClosed] = useState(true);
    const [formData, setFormData] = useState({ ...formDataInitialValues });

    const { t, locale } = React.useContext(LocalizationContext);
    const { user } = React.useContext(UserContext);
    const { page, queryParams: { url: forwardUrl }, params } = React.useContext(RouteContext);

    const {
        domainSettings: {
            registerRewardAmount,
            registerRewardCurrency,
        },
        isRegisterRewardEnabledAndConfigured,
        baseUrl
    } = React.useContext(SettingsContext);

    const type = isRegisterRewardEnabledAndConfigured
        ? RegisterFlowTypesEnum.REWARD
        : RegisterFlowTypesEnum.SIMPLE;
    const registerFlow = RegisterFlowTypeSteps.find(item => item.type === type);
    const { steps } = registerFlow;

    const nextStep = () => {
        const currentStepIndex = steps.indexOf(step)
        const nextStepValue = steps[currentStepIndex >= 0 ? currentStepIndex + 1 : 0] || RegisterFlowStepsEnum.FINISHED

        setStep(nextStepValue);

        nextStepValue === RegisterFlowStepsEnum.FINISHED && forwardToExploreCourses()
    }


    const onLogin = () => {
        // NOOP for now
    }

    const setInputData = (name: string, value: any) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const onCancel = () => {
        setVisible(false)
        setUserNotClosed(false)
    }

    const afterClose = () => {
        if (forwardUrl && step !== RegisterFlowStepsEnum.FINISHED) {
            window.location.href = forwardUrl;
        }
    }

    const forwardToExploreCourses = useCallback(() => {
        if (user && 'index' === page) {
            Router.pushRoute('favourites', { locale })
        }

        return true
    }, [user, page, locale])

    const isNotLoggedIn = useCallback(() => {
        return !user;
    }, [user])

    const isJustRewardedUser = useCallback(() => {
        return user && user.isRewarded;
    }, [user])

    const isNotEmailLoginPopupMode = useCallback(() => {
        return !forwardUrl;
    }, [forwardUrl])

    const isNotInRegisterToCourseMode = useCallback(() => {
        return !(page === 'courseShow' && params?.register === "register");
    }, [page, params])

    const isNewUser = useCallback(() => {
        return user && user.isNewUser
    }, [user])

    const isPageWhitelisted = useCallback(() => {
        return ['index', 'landing', 'paths', 'blog', 'search', 'schoolShow', 'courseShow'].includes(page)
    }, [page])

    const thereWasNoAnyPreviousUserSession = useCallback(() => {
        return !cookieExist('token');
    }, []);

    const subscribeButtonOnClick = async () => {
        if (!emailRegex.test(formData.email)) {
            notification.error({
                message: t('not.correct.email'),
            })

            return false
        }

        if (!phoneRegex.test(formData.phone)) {
            notification.error({
                message: t('not.correct.phone'),
            })

            return false
        }

        const tags = [];
        if (formData.newsletter) {
            tags.push('newsletter');
        }
        if (formData.discounted) {
            tags.push('discounted');
        }
        const editedUser = { ...user, email: formData.email, phone: formData.phone };

        await subscribeToList(editedUser, tags);

        return true
    };

    const preferencesOnContinue = async () => {
        const selectedPref = Object.entries(formData.preferences).filter(([, value]) => value).map(([key,]) => key);

        if (selectedPref.length < 3) {
            notification.error({
                message: t('bonus.popup.choose.preferences'),
            })

            return false
        }

        await api.post('user-subscriptions', {
            email: formData.email,
            competences: selectedPref,
            page: `afterRegisterPopup`,
            userTrackingId: getCookie('sync'),
        }).catch(() => null)

        return true
    };


    const shouldModalShown = useCallback(() => {
        const shouldModalShown = (
            // before login case
            isPageWhitelisted() && isNotEmailLoginPopupMode() && isNotLoggedIn() && thereWasNoAnyPreviousUserSession() && isRegisterRewardEnabledAndConfigured ||
            // after login case
            isJustRewardedUser() && isNotInRegisterToCourseMode()
        ) &&
            userNotClosed &&
            RegisterFlowStepsEnum.FINISHED !== step

        return shouldModalShown;
    }, [
        step,
        isPageWhitelisted,
        userNotClosed,
        isJustRewardedUser,
        isNotLoggedIn,
        thereWasNoAnyPreviousUserSession,
        isNewUser,
        isRegisterRewardEnabledAndConfigured,
        isNotEmailLoginPopupMode,
        isNotInRegisterToCourseMode
    ]);

    useEffect(() => {
        setVisible(shouldModalShown());
    }, [shouldModalShown])

    useEffect(() => {
        // onUserLogout reset data
        if (user === null) {
            setUserNotClosed(true);
            setStep(RegisterFlowStepsEnum.EMPTY);
            setFormData(formDataInitialValues);
        }
    }, [user])

    useEffect(() => {
        if (step !== RegisterFlowStepsEnum.FINISHED && userNotClosed) {
            let initStep = steps[0];

            if (isNewUser()) {
                initStep = RegisterFlowStepsEnum.SUBSCRIBE;
            } else if (isJustRewardedUser()) {
                initStep = RegisterFlowStepsEnum.REWARD_CONGRATS;
            }

            setStep(initStep);
        };

        setFormData({ ...formData, email: user && user.email });
    }, [user, page, steps, isJustRewardedUser, isNewUser]);

    let modalProps: ModalPropsInterface = null;

    const rewardImageHeadline = (
        <div className={styles.rewardImageText}>
            <div className={styles.imageTextTop}>{t('bonus.popup.join.us')}</div>
            <div className={styles.imageTextMiddle}>
                {registerRewardAmount}
                <div className={styles.imageTextCurrency}>{registerRewardCurrency}</div>
            </div>
            <div className={styles.imageTextBottom}>bonus</div>
        </div>
    )

    const standardLoginImageHeadline = (
        <div className={styles.loginImageText}>
            <div className={styles.imageTextTop}>You will have access to</div>
            <div className={styles.imageTextMiddle}>24,000+</div>
            <div className={styles.imageTextBottom}>Courses</div>
        </div>
    )

    const standardSubscribeImageHeadline = (
        <div className={styles.subscribeImageText}>
            <div className={styles.imageTextTop}>{t('bonus.popup.subtitle')}</div>
            <div className={styles.imageTextMiddle}>{t('bonus.popup.title')}</div>
            {/* <div className={styles.imageTextBottom}>Your courses</div> */}
        </div>
    )

    const standardPreferencesImageHeadline = (
        <div className={styles.prefImageText}>
            <div className={styles.imageTextTop}>{t('bonus.popup.subtitle')}</div>
            <div className={styles.imageTextMiddle}>{t('bonus.popup.title')}</div>
            {/* <div className={styles.imageTextBottom}>Content</div> */}
        </div>
    )

    switch (step) {
        case RegisterFlowStepsEnum.REWARD:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: '/static/images/modal/register-flow/bonus.png',
                    imageHeadlineComponent: rewardImageHeadline,
                    modalHeadlineComponent: <div className={styles.rewardHeadline}>
                        <div>{t('bonus.popup.headline')}</div>
                        <div className={styles.rewardAmountText}>{registerRewardCurrency} {registerRewardAmount} {t('bonus.popup.bonus')}</div>
                        <div className={styles.rewardHeadlineDesc}>{t('bonus.popup.join.us')}</div>
                    </div>,
                    modalBodyComponent: (
                        <div>{t('bonus.popup.description')}</div>
                    ),
                    modalFooterComponent: <ContinueButton text={t('bonus.popup.continue')} nextStep={nextStep} />
                },
            };
            break;
        case RegisterFlowStepsEnum.LOGIN_BUTTONS:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: isRegisterRewardEnabledAndConfigured ? '/static/images/modal/register-flow/bonus.png' : '/static/images/modal/register-flow/login.png',
                    imageHeadlineComponent: isRegisterRewardEnabledAndConfigured ? rewardImageHeadline : standardLoginImageHeadline,
                    modalHeadlineComponent: <div className={styles.loginHeadline}>
                        <div>{t('bonus.popup.login')} 🙌</div>
                        <div className={styles.loginHeadlineDesc}>{t('bonus.popup.login.description')}</div>
                    </div>,
                    modalBodyComponent: (
                        <div className={styles.loginButtons}>
                            <LoginDialog onLogin={onLogin} />
                        </div>
                    ),
                },
            };
            break;
        case RegisterFlowStepsEnum.SUBSCRIBE:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: isRegisterRewardEnabledAndConfigured ? '/static/images/modal/register-flow/bonus.png' : '/static/images/modal/register-flow/login.png',
                    imageHeadlineComponent: isRegisterRewardEnabledAndConfigured ? rewardImageHeadline : standardSubscribeImageHeadline,
                    // {t('newsletter.popup.image.headline')}
                    // {t('newsletter.popup.text.headline')}
                    modalHeadlineComponent: <div className={styles.subscribeHeadline}>
                        <div>{t('bonus.popup.subscribe')}👌!</div>
                        <div className={styles.subscribeHeadlineDesc}>{t('bonus.popup.subscribe.text')}</div>
                    </div>,
                    modalBodyComponent: (
                        <div className={styles.subscribeBody}>
                            <Subscribe {...formData} setInputData={setInputData} />
                        </div>
                    ),
                    // {t('newsletter.popup.button')}
                    modalFooterComponent: <ContinueButton text={t('bonus.popup.button.join')} nextStep={nextStep} beforeNextStep={subscribeButtonOnClick} />
                },
            };
            break;
        case RegisterFlowStepsEnum.PREFERENCES:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: isRegisterRewardEnabledAndConfigured ? '/static/images/modal/register-flow/bonus.png' : '/static/images/modal/register-flow/login.png',
                    imageHeadlineComponent: isRegisterRewardEnabledAndConfigured ? rewardImageHeadline : standardPreferencesImageHeadline,
                    modalHeadlineComponent: <div className={styles.preferencesHeadline}>
                        <div>{t('bonus.popup.preferences')} 😇!</div>
                        <div className={styles.prefHeadlineDesc}>{t('bonus.popup.choose.subjects')}</div>
                    </div>,
                    modalBodyComponent: <div className={styles.preferencesBody}>
                        <Preferences preferences={formData.preferences} setInputData={setInputData} />
                    </div>,
                    modalFooterComponent: <div className={styles.preferencesFooter}>
                        <div className={styles.prefRequiredCount}>{t('bonus.popup.choose.preferences')}</div>
                        <ContinueButton text={t('bonus.popup.button.join')} nextStep={nextStep} beforeNextStep={preferencesOnContinue} />
                    </div>,
                },
            };
            break;
        case RegisterFlowStepsEnum.REWARD_CONGRATS:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: '/static/images/modal/register-flow/bonus.png',
                    imageHeadlineComponent: isRegisterRewardEnabledAndConfigured ? rewardImageHeadline : standardPreferencesImageHeadline,
                    modalHeadlineComponent: (<div className={styles.rewardCongratsHeadline}>
                        <div>{t('bonus.popup.congrats')}</div>
                        <div className={styles.rewardAmountText}>{registerRewardCurrency} {registerRewardAmount} {t('bonus.popup.bonus')}</div>
                    </div>
                    ),
                    modalBodyComponent: (
                        <div className={styles.rewardCongratsBody}>
                            <div>
                                {t('bonus.congrats.description')}
                            </div>
                            <div className={styles.rewardCongratsShareContainer}>
                                <div className={styles.rewardCongratsShareHeadline}>{t('bonus.popup.share')}</div>
                                <div className={styles.rewardCongratsShareItems}>
                                    <div>
                                        <FacebookShareButton
                                            style={{
                                                padding: "10px 16px",
                                                border: "0.8px solid #EAEAEB",
                                                boxSizing: "border-box",
                                                borderRadius: "8px",
                                                width: "100%",
                                                textAlign: "left"
                                            }}
                                            url={`${baseUrl}?utm_source=${UtmSources.facebook}&utm_content=${UtmContents.bonus_share}`}>
                                            <img
                                                src="/static/images/modal/register-flow/facebook-share-icon.png"
                                                alt="facebook icon"
                                                className={styles.facebookIcon}
                                            />
                                            {t('bonus.popup.share.fb')}
                                        </FacebookShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    modalFooterComponent: <ContinueButton text={t('bonus.popup.explore.courses')} nextStep={nextStep} />
                }
            };
            break;
        case RegisterFlowStepsEnum.WELCOME:
            modalProps = {
                layout: LayoutTypesEnum.IMAGE_AND_DATA,
                data: {
                    image: '/static/images/modal/register-flow/welcome.png',
                    modalHeadlineComponent: <div className={styles.rewardCongratsHeadline}>
                        <div>You are all done!</div>
                        <div className={styles.welcomeHeadlineDesc}>Welcome to your courses</div>
                    </div>,
                    modalBodyComponent: <div className={styles.welcomeBody}>
                        You will find your schoolmates, schedules,  documents
                        test results, evaluations and everything related to
                        your courses
                    </div>,
                    modalFooterComponent: <ContinueButton text={'Explore Courses'} nextStep={nextStep} />
                },
            };
            break;
        default:
            return null;
    }

    return <Modal {...modalProps} className={'register-flow-modal'} afterClose={afterClose} onCancel={onCancel} visible={visible} />

};

export default RegisterFlow;
