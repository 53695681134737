import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { UserContext } from '../../../hoc/User';
import { getCookie } from '../../../utils/cookies';
import { SettingsContext } from '../../../hoc/Settings';
import { LocalizationContext } from '../../../hoc/Localization';
import Button from '../../Button';
import Input from '../../Input';
import styles from '../styles.less';

const MainView = ({ onLogin, title, setStep }) => {
  const cookieReferral = getCookie('referral');
  const [referral, setReferral] = React.useState(cookieReferral);
  if (referral !== cookieReferral) {
    setReferral(cookieReferral);
  }

  const { facebookLogin, googleLogin, initLoginStatus } = React.useContext(UserContext);
  const { t } = React.useContext(LocalizationContext);
  const {
    domainSettings: {
      google: { clientId },
    },
  } = React.useContext(SettingsContext);

  React.useEffect(() => {
    initLoginStatus()
  }, []);

  return (
    <>
      <Head>
        <script src="https://apis.google.com/js/platform.js" async defer />
        <meta name="google-signin-client_id" content={clientId} />
      </Head>
      <div className={`${styles.container}`}>
        <h1 className={styles.loginDialogTitle}>{t('login.register.text')}</h1>
        {!!referral && (
          <section>
            <Input
              version="long"
              size="large"
              placeholder="Referral ID"
              defaultValue={referral}
              onInput={e => setReferral(e.target.value || null)}
            />
          </section>
        )}
        {!!title && (
          <div>
            <h2 className={styles.titleTutor}>{title}</h2>
          </div>
        )}
        <section className={styles.loginButtons}>
          <Button
            version="large"
            className="kep-login-facebook metro safari_only"
            onClick={() => facebookLogin(onLogin, referral)}
          >
            Login with Facebook
          </Button>
        </section>
        <section className={styles.loginButtons}>
          <Button
            version="large"
            className="kep-login-google metro login-dialog-google safari_only"
            onClick={() => googleLogin(onLogin, referral)}
          >
            Login with Google
          </Button>
        </section>
        <section className={styles.loginButtons}>
          <Button
            version="large"
            className="kep-login-email metro login-dialog-google safari_only"
            onClick={() => setStep(2)}
            data_gh="login_email_button"
          >
            {t('login.with.email')}
          </Button>
        </section>
      </div>
    </>
  );
};

MainView.propTypes = {
  setStep: PropTypes.func,
  onLogin: PropTypes.func,
  title: PropTypes.string,
};

MainView.defaultProps = {
  title: '',
  setStep: () => { },
  onLogin: () => { },
};

export default MainView;
