import React from 'react';
import { Modal } from 'antd';
import { ModalPropsInterface } from './Interfaces/ModalInterface';
import styles from './styles.less';

const CustomModal: React.FunctionComponent<ModalPropsInterface> = ({
	layout,
	data,
	width,
	visible: visibleExternal,
	onCancel,
	footer,
	bodyStyle,
	centered,
	...props
}) => {
	const [visible, setVisible] = React.useState(null);

	function hideModal() {
		setVisible(false);
	}

	const layoutClassName = `${layout}Layout`;
	const ImageHeadlineComponent: JSX.Element = data.imageHeadlineComponent || <></>;
	const ModalHeadlineComponent: JSX.Element = data.modalHeadlineComponent || <></>;
	const ModalBodyComponent: JSX.Element = data.modalBodyComponent || <></>;
	const ModalFooterComponent: JSX.Element = data.modalFooterComponent || <></>;
	const ImageComponent: JSX.Element = data.image ? (
		<div className={styles.imageWrapper}>
			<img alt="img" src={data.image} />
			<div className={styles.imageHeadlineComponentWrapper}>{ImageHeadlineComponent}</div>
		</div>
	) : (
			<></>
		);

	return (
		<Modal
			{...props}
			visible={visible === null ? visibleExternal : visible}
			onCancel={onCancel || hideModal}
			maskClosable={false}
			footer={footer || null}
			bodyStyle={bodyStyle || { padding: '0' }}
			width={width || '913px'}
			centered={centered || true}
			closeIcon={
				<img className={styles.closeIcon} src="/static/images/modal/close.png" alt="close" />
			}
			className={`${styles.modal} ${props.className}`}
		>
			<div className={styles.modalContainer}>
				{ImageComponent}
				<div className={`${styles.modalWrapper} ${styles[layoutClassName]}`}>
					<div className={styles.modalHeadlineComponentWrapper}>{ModalHeadlineComponent}</div>
					<div className={styles.modalBodyComponentWrapper}>{ModalBodyComponent}</div>
					<div className={styles.modalFooterComponentWrapper}>{ModalFooterComponent}</div>
				</div>
			</div>
		</Modal>
	);
};

export default CustomModal;
